import { Grid } from 'components/ui';
import { Theme } from 'themes';

import { WORK_STRINGS } from '../../work.strings';

import { JobPromotionSubtitle } from '../job-promotion-subtitle';
import { JobPromotionButtons } from '../job-promotion-buttons';

import { JobPromotionItem } from './job-promotion-item';

import { useJobPromotion } from './job-promotion.state';
import { JobPromotionStyles as Styles } from './job-promotion.styles';

const { JOB_PROMOTION } = WORK_STRINGS;

export const JobPromotion = () => {
  const {
    isIntern,
    nextLevels,
    selectedJob,
    isLoading,
    setIsLoading,
    setWorkData,
  } = useJobPromotion();

  const title = isIntern ? JOB_PROMOTION.TITLE_INTERN : JOB_PROMOTION.TITLE;

  const RenderJobs = nextLevels?.map((job) => (
    <JobPromotionItem
      key={job}
      job={job}
      selectJob={selectedJob}
      onSelectWork={setWorkData}
    />
  ));

  return (
    <Grid alignContent="space-between" rowGap="24px">
      <Theme.Wrapper $itemGap={{ default: '24px' }} $textAlign="center">
        <Theme.Title>{title}</Theme.Title>

        <Theme.Wrapper $itemGap={{ default: '24px' }}>
          <JobPromotionSubtitle isIntern={isIntern} />

          <Styles.CenteredContainer>{RenderJobs}</Styles.CenteredContainer>
        </Theme.Wrapper>
      </Theme.Wrapper>

      <JobPromotionButtons
        isLoading={isLoading}
        selectedJob={selectedJob}
        onSetLoading={setIsLoading}
      />
    </Grid>
  );
};
