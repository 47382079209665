import { useCallback, useState } from 'react';

import { useRealtimeFire, useReduxSelector } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

export const useFireWork = () => {
  const isInProgress = useReduxSelector(
    (redux) => redux.game.status === 'in-progress'
  );

  const [isShow, setIsShow] = useState(false);

  const onVal = useCallback((val: any) => {
    if (val) {
      setIsShow(true);
    }
  }, []);

  useRealtimeFire({ onVal });

  const onFindNewJob = useCallback(() => {
    setIsShow(false);

    if (isInProgress) {
      moveToRoute(ROUTES.findJob);
    }
  }, [isInProgress]);

  return { isShow, isInProgress, onFindNewJob };
};
