import { memo } from 'react';

import { CompanyParameters } from 'components/company-parameters';

import { FIND_JOB_STRINGS } from '../../../../find-job.strings';
import { CompanyHead } from '../company-head';

import { useCompanyCard } from './company-card.state';
import { ICompanyCardProps } from './company-card.typing';
import { CompanyCardStyles as Styles } from './company-card.styles';

const { JOB_OFFER } = FIND_JOB_STRINGS;

export const CompanyCard = memo((props: ICompanyCardProps) => {
  const {
    companyName,
    job,
    valuesInfo,
    cycles,
    sector,
    companyBonus,
    onReject,
    onSetModal,
  } = props;

  const { displayCompany, isLimited, totalSalary, onAccept, handleReject } =
    useCompanyCard({
      valuesInfo,
      sector,
      job,
      companyName,
      companyBonus,
      onSetModal,
      onReject,
    });

  const { values, mood } = displayCompany;

  return (
    <Styles.Root $itemGap={{ default: '20px' }}>
      <CompanyHead
        company={companyName}
        job={job}
        salary={totalSalary}
        mood={mood}
        cycles={cycles}
      />

      <CompanyParameters
        positiveParameters={values.positive}
        negativeParameters={values.negative}
      />

      <Styles.ButtonsContainer $justifyContent="center" $itemGap="32px">
        <Styles.Button
          title={JOB_OFFER.REJECT_WORK}
          styleTheme="cancelModal"
          onClick={handleReject}
        />
        <Styles.Button
          title={JOB_OFFER.ACCEPT_WORK}
          onClick={onAccept}
          isDisabled={isLimited}
        />
      </Styles.ButtonsContainer>
    </Styles.Root>
  );
});
