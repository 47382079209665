import { MutableRefObject } from 'react';
import { Tabs, TabPanel } from 'react-tabs';

import { Lifestyle } from 'components/lifestyle';
import { Profile } from 'components/profile';

import { HOME_STRINGS } from 'screens/home/home.strings';

import { Achievement } from '../achievement';
import { Ranking } from '../ranking';
import { History } from '../history';

import {
  HomeTabsStyles as Styles,
  HomeTabList,
  HomeTab,
} from './home-tabs.styles';
import { useReduxSelector } from 'services/hooks';

interface IHomeTabsProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
}

const { TABS } = HOME_STRINGS;

export const HomeTabs = (props: IHomeTabsProps) => {
  const { scrollRef } = props;

  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );

  const RenderRankingTab = isSelfPaced ? null : (
    <HomeTab>{TABS.RANKING}</HomeTab>
  );

  const RenderRankingPanel = isSelfPaced ? null : (
    <TabPanel>
      <Ranking />
    </TabPanel>
  );

  return (
    <Styles.Root>
      <Tabs>
        <HomeTabList>
          <HomeTab>{TABS.ACHIEVEMENT}</HomeTab>
          <HomeTab>{TABS.LIFESTYLE}</HomeTab>
          <HomeTab>{TABS.PROFILE}</HomeTab>
          {RenderRankingTab}
          <HomeTab>{TABS.HISTORY}</HomeTab>
        </HomeTabList>

        <Styles.PanelsWrapper>
          <TabPanel>
            <Achievement />
          </TabPanel>

          <TabPanel>
            <Lifestyle />
          </TabPanel>

          <TabPanel>
            <Profile />
          </TabPanel>

          {RenderRankingPanel}

          <TabPanel>
            <History scrollRef={scrollRef} />
          </TabPanel>
        </Styles.PanelsWrapper>
      </Tabs>
    </Styles.Root>
  );
};
