import { Theme } from 'themes';

import { WORK_STRINGS } from '../work.strings';

interface IProps {
  isIntern?: boolean;
}

const JOB_PROMOTION = WORK_STRINGS.JOB_PROMOTION;

export const JobPromotionSubtitle = (props: IProps) => {
  const { isIntern } = props;

  const RenderSubtitle = isIntern ? (
    <>
      Select your new role below
      <br />
      <br />
      Note: Rejecting means competing for roles in the market you may not yet
      have the skills for.
    </>
  ) : (
    JOB_PROMOTION.SUBTITLE
  );

  return <Theme.Paragraph>{RenderSubtitle}</Theme.Paragraph>;
};
