import { useMemo, useState } from 'react';

import { ROUTES } from 'constants/routes';
import { useReduxSelector, useSlider } from 'services/hooks';
import { moveToRouteCallback } from 'services/utils';

import {
  createOnboardingSliderSetting,
  getOnBoardingItems,
} from './onboarding.utils';

export const useOnboarding = () => {
  const [slide, setSlide] = useState(0);

  const version = useReduxSelector((redux) => redux.app.version);
  const isGauges = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.gauges)
  );
  const isSelfPaced = useReduxSelector((redux) =>
    Boolean(redux.game.config?.isSelfPaced)
  );
  const endAt = useReduxSelector((redux) => redux.game.config?.endAt);
  const rounds = useReduxSelector((redux) => redux.game.config?.roundsNumber);
  const roundDuration = useReduxSelector(
    (redux) => redux.game.config?.roundDuration
  );

  const { ref, nextSlide } = useSlider();

  const setting = createOnboardingSliderSetting(slide, setSlide);

  const onboardingItems = useMemo(
    () =>
      getOnBoardingItems({
        isGauges,
        isSelfPaced,
        endAt,
        roundDuration,
        rounds,
        version,
      }),
    [endAt, isGauges, isSelfPaced, roundDuration, rounds, version]
  );

  const handleClick =
    slide === onboardingItems.length - 1
      ? moveToRouteCallback(ROUTES.createCharacter)
      : nextSlide;

  return { ref, setting, onboardingItems, handleClick };
};
