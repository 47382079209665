import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { getNowMoment } from 'services/utils';

import { callSelfPacedAction } from '../api';
import { GameActions, PlayerActions } from '../redux';

import { useReduxSelector } from './redux';
import { IIntervalJob } from 'typings/interval-jobs.typing';

export const useEndSelfPacedRound = () => {
  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );

  const dispatch = useDispatch();

  const endSelfPacedRound = useCallback(async () => {
    if (!isSelfPaced) {
      return;
    }

    try {
      const result = await callSelfPacedAction('@SELF_PACED/END_ROUND', null);

      if (result?.mergePlayer) {
        dispatch(PlayerActions.merge(result.mergePlayer));
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, isSelfPaced]);

  return { endSelfPacedRound };
};

export const useSelfPacedGameEnd = () => {
  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );
  const gameStatus = useReduxSelector((redux) => redux.game.status);

  const endAt = useReduxSelector((redux) => redux.game.config?.endAt);

  const endMoment = useMemo(() => dayjs(endAt), [endAt]);

  const dispatch = useDispatch();

  const { endSelfPacedRound } = useEndSelfPacedRound();

  const checkAndFinishGame = useCallback(() => {
    if (
      isSelfPaced &&
      endAt &&
      gameStatus !== 'finished' &&
      getNowMoment().isAfter(endMoment)
    ) {
      dispatch(GameActions.merge({ status: 'finished' }));

      endSelfPacedRound();
    }
  }, [isSelfPaced, endAt, gameStatus, endMoment, dispatch, endSelfPacedRound]);

  return { checkAndFinishGame };
};

export const useSelfPacedGameIntervalJob = () => {
  const { checkAndFinishGame } = useSelfPacedGameEnd();

  const job: IIntervalJob = useMemo(
    () => ({
      handler: checkAndFinishGame,
      interval: 1,
    }),
    [checkAndFinishGame]
  );

  return job;
};
