import { Settings } from 'react-slick';
import { TGameVersion } from '@avid/common';

import { getVersionString } from 'services/utils/versions';

import { IOnboardingItemProps } from './onboarding-item';
import { OnboardingStyles } from './onboarding.styles';
import dayjs from 'dayjs';

interface IGetOnboardingItemsParams {
  isSelfPaced: boolean;
  isGauges: boolean;
  version?: TGameVersion;
  rounds?: number;
  endAt?: string;
  roundDuration?: number;
}

const VERSIONS_CAREER_CENTRE_MAP = {
  standard:
    'All you need to know about jobs and careers in Singaland is in the Career Centre',
  us: 'All you need to know about jobs and careers in Empowerland is in the Career Centre',
  ntu: [
    'You can find out all about the jobs and skill requirements in NTUniverse by visiting the Career & Attachment Office (CAO).',
    'Similarly, the CAO in NTU develops and empowers students to reach their potential and fulfill career aspirations through providing many helpful resources.',
  ],
};

function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours}-hour${hours > 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}-minute${minutes > 1 ? 's' : ''}`);
  }

  return parts.join(' ');
}

export const getOnBoardingItems = (
  params: IGetOnboardingItemsParams
): IOnboardingItemProps[] => {
  const { isGauges, isSelfPaced, endAt, roundDuration, rounds, version } =
    params;

  const land = getVersionString(version, 'LAND');
  const careerCentre = getVersionString(version, 'CAREER_CENTRE') as string;

  const items: IOnboardingItemProps[] = [
    {
      imagePath: 'onboarding1',
      title: `Welcome to ${land}!`,
      text: "You'll guide your character through life in this new world by gaining skills through work and education.",
    },
    {
      imagePath: 'onboardingEnergy',
      text: isSelfPaced
        ? [
            "In life, you only have a limit of 24 hours a day, 7 days a week. Here, you'll get a fixed amount of Energy to use per round.",
            'Your unused energy rolls over to the next round after each round ends.',
            'Choose wisely!',
          ]
        : [
            'In life, you only have a limit of 24 hours a day, 7 days a week.',
            "Here, you'll get a fixed amount of Energy to use per round. Choose wisely!",
          ],
      title: 'Energy',
    },
    {
      imagePath: 'onboarding3',
      title: 'Bank Account and Living Expenses',
      text: [
        'You can earn money while working and spend it however you wish.',
        'You will also have to pay for living expenses as you play the game.',
      ],
    },
    {
      imagePath: 'onboarding4',
      title: 'Life Card',
      text: "At the start of each round, you'll get a Life Card - events that could happen to you in life.",
    },
    {
      imagePath: 'onboarding5',
      title: 'Satisfaction Level',
      text: isGauges
        ? 'Your character will be happy in the game if you align your job to your Values and increase your health, work and play in your Life Dashboard.'
        : 'As you work, your Satisfaction Level is affected by how the company aligns with your Career Anchors and Values.',
    },
    {
      imagePath: 'onboarding6',
      title: careerCentre,
      text: VERSIONS_CAREER_CENTRE_MAP[version || 'standard'],
    },
  ];

  if (isSelfPaced) {
    const endAtFormat = dayjs(endAt).format('Do MMMM [at] h:mm A');

    items.push({
      imagePath: 'onboardingSelfPaced',
      title: 'Play at Your Own Pace',
      text: [
        `This game has ${rounds} rounds, and you’ll need to complete all of them before the game ends on ${endAtFormat}`,
        `The round will end either when the ${formatTime(+(roundDuration || 0))} timer runs out or when you’ve used up all your energy for that round. Once a round begins, you won’t be able to pause.`,
        'Plan your moves wisely and make the most of your time and energy!',
      ],
    });
  }

  return items;
};

export const BUTTON_TEXT = 'Next';

export const createOnboardingSliderSetting = (
  slide: number,
  setSlide: (slide: number) => void
): Settings => ({
  dots: true,
  infinite: false,
  arrows: false,
  appendDots: (dots) => (
    <OnboardingStyles.DotBar>{dots}</OnboardingStyles.DotBar>
  ),
  customPaging: (i) => <OnboardingStyles.Dot isActive={i === slide} />,
  afterChange: (nextSlide) => setSlide(nextSlide),
});
