import { TImageNames } from 'components/image';

import { StatItem } from './components/stat-item';
import { RoundTimer } from './components/round-timer';
import { RoundsCounter } from './components/rounds-counter';

import { useTopBar } from './top-bar.state';
import { TopBarStyles as Styles } from './top-bar.styles';

const getEmotion = (mood: number): TImageNames => {
  if (mood <= 40) {
    return 'badMood';
  }
  if (mood <= 60) {
    return 'neutralMood';
  }
  return 'goodMood';
};

export const TopBar = () => {
  const { energy, money, mood } = useTopBar();

  return (
    <Styles.Root>
      <Styles.ResponsiveContainer $justifyContent="space-between">
        <StatItem value={money} image="money" id="top_bar-money" />
        <StatItem value={energy} image="power" id="top_bar-energy" />
        <StatItem value={mood} image={getEmotion(mood)} />

        <RoundsCounter />
        <RoundTimer />
      </Styles.ResponsiveContainer>
    </Styles.Root>
  );
};
