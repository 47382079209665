import { ButtonOld } from 'components/button';
import { Grid } from 'components/ui';

import {
  IUseJobPromotionButtonProps,
  useJobPromotionButtons,
} from './job-promotion-buttons.state';

export const JobPromotionButtons = (props: IUseJobPromotionButtonProps) => {
  const { isLoading, selectedJob, onSetLoading } = props;

  const { isIntern, onConfirm, onReject } = useJobPromotionButtons({
    isLoading,
    selectedJob,
    onSetLoading,
  });

  const RenderRejectButton = isIntern ? (
    <ButtonOld title="Reject" styleTheme="cancelModal" onClick={onReject} />
  ) : null;

  return (
    <Grid rowGap="20px">
      <ButtonOld
        title={(isIntern ? 'Accept' : 'Confirm') + ' new job'}
        isDisabled={!selectedJob || isLoading}
        onClick={onConfirm}
      />

      {RenderRejectButton}
    </Grid>
  );
};
