import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TGameVersion } from '@avid/common';

import { MainActions } from 'screens/main';
import { useFetch, useReduxSelector } from 'services/hooks';
import { getRandomItem } from 'services/utils';
import { VersionsAPI } from 'services/api';

import { IWorkJSXProps, WorkModal } from '../../work.typings';
import { WorkContext } from '../../work.context';

export interface IWorkTestProps extends IWorkJSXProps {
  rating: number;
  onSetRating: (rating: number) => void;
}

export const getQuestionAsync = async (
  version: TGameVersion,
  sector: string,
  job: string
) => {
  const questionsList = await VersionsAPI.sectors.work.job.fetchQuestions({
    version,
    sector,
    job,
  });

  if (!questionsList) {
    return;
  }

  return getRandomItem(questionsList);
};

export const useWorkTest = () => {
  const { onOpenModal, rating, onSetRating } = useContext(WorkContext);

  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const work = useReduxSelector((redux) => redux.player.work?.work);

  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );
  const version = useReduxSelector((redux) => redux.app.version);

  const dispatch = useDispatch();

  const getQuestionsCallback = useCallback(() => {
    if (!version || !sector || !work) {
      return;
    }

    return getQuestionAsync(version, sector, work);
  }, [version, sector, work]);

  const onEndTest = useCallback(
    () => onOpenModal(WorkModal.CompleteWork),
    [onOpenModal]
  );

  const onAnswerQuestion = useCallback(
    (percent: number) => onSetRating(rating + percent),
    [rating, onSetRating]
  );

  const [question, isLoadingQuestion] = useFetch(getQuestionsCallback);

  useEffect(() => {
    return () => {
      if (isSelfPaced) {
        dispatch(MainActions.update({ isActing: false }));
      }
    };
  }, [dispatch, isSelfPaced]);

  return { question, isLoadingQuestion, onEndTest, onAnswerQuestion };
};
