import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IPlayer } from '@avid/common';

import { AppActions } from 'screens/app';
import { gamesAPI } from 'services/api';
import { appAuth } from 'services/firebase';
import { PlayerActions, INIT_PLAYER_STATE, GameActions } from 'services/redux';

export const useLoadGamePlayer = () => {
  const dispatch = useDispatch();

  const loadGamePlayer = useCallback(
    async (gameCode: string, email: string) => {
      const [player, game] = await Promise.all([
        gamesAPI.getPlayer(gameCode, email),
        gamesAPI.getGame(gameCode),
      ]);

      if (player && game) {
        dispatch(
          AppActions.updateGameConstants({
            minimalMoney: +(game?.config.minimalMoney || 0),
            startEducationOrder: game?.config.startEducationOrder,
            version: game?.config.version,
            gameMods: game?.config.mods,
          })
        );

        dispatch(
          GameActions.merge({
            config: {
              roundsNumber: +game?.config.roundsNumber,
              roundDuration: game?.config.roundDuration,
              isSelfPaced: Boolean(game.config.isSelfPaced),
              endAt: game.config.endAt,
            },
          })
        );

        dispatch(PlayerActions.set((player as IPlayer) || INIT_PLAYER_STATE));
      }
    },
    [dispatch]
  );

  const loadGameAuthPlayer = useCallback(async () => {
    const user = appAuth.currentUser;

    const tokenResult = await user?.getIdTokenResult(true);

    const gameCode = tokenResult?.claims.game;

    if (gameCode && user?.email) {
      return loadGamePlayer(gameCode, user.email);
    }
  }, [loadGamePlayer]);

  return loadGameAuthPlayer;
};
