import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import durationPlugin from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utcPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(advancedFormat);

export const getNowMoment = () => dayjs().utc(false);

const formatNumber = (value: number) => (value >= 10 ? value : `0${value}`);

export const formatTime = (seconds: number) => {
  const duration = dayjs.duration(seconds * 1000);

  const { days, hours, minutes, sec } = {
    days: duration.get('d'),
    hours: duration.get('hour'),
    minutes: duration.get('m'),
    sec: duration.get('s'),
  };

  const ms = `${formatNumber(minutes)}:${formatNumber(sec)}`;

  if (!days) {
    if (!hours) {
      return ms;
    }

    return `${formatNumber(hours)}:${ms}`;
  }

  return `${formatNumber(days)}:${formatNumber(hours)}:${ms}`;
};

export const getRoundSecondsPassed = (roundStartUnix: number) =>
  Math.floor(getNowMoment().unix() - roundStartUnix);

export const getRoundSecondsLeft = (
  roundDuration: number,
  secondsPassed: number
) => Math.max(roundDuration - secondsPassed, 0);
