import {
  IAbility,
  IEducationLevel,
  IGame,
  IGameIdentity,
  IPlayer,
  IPlayerGauges,
  IPlayerTutorials,
  IQuestion,
  TClientId,
  TGameStatus,
  TPossessionLevel,
} from '@avid/common';

import { CLIENT_ID } from 'constants/settings';
import { IStartRoundData } from 'typings/rounds';
import { TCreateCharacterProps } from 'typings/create-character';

import { firebaseFunctions } from '../../firebase';

interface IClientIdParam {
  clientId: TClientId;
}

interface IMergePlayerData {
  mergePlayer: TDeepPartial<IPlayer>;
}

interface ILimitAvailableJobsActionParams {
  sector: string;
  jobs: string[];
}

interface IAuthPlayerParams {
  code: string;
}

interface IConnectGameData {
  player: IPlayer;
  game: IGame;
}

interface ICreateCharacterParams extends IClientIdParam {
  createCharacter: TCreateCharacterProps;
  lifeGoals?: number[];
}

interface ICreateCharacterData {
  mergePlayer: TDeepPartial<IPlayer>;
  gameStatus: TGameStatus;
}

interface IGetLifeCardsData {
  lifeCards: ILifeCard[];
}

interface IApplyLifeCardParams extends IClientIdParam {
  title: string;
}

interface IApplyLifeCardData {
  mergePlayer: TDeepPartial<IPlayer>;
  bonuses?: string[];
  skills?: string[];
}

interface IPayLifeExpensesData {
  mergePlayer: TDeepPartial<IPlayer>;
  amount: number;
}

interface PLAYER_ACTIONS {
  // Education
  EDUCATION_START: '@EDUCATION/START';
  EDUCATION_COMPLETE: '@EDUCATION/COMPLETE';
}

interface IStartEducationPayload {
  level: string;
  sector?: string;
}

interface IStartEducationActionParams {
  type: PLAYER_ACTIONS['EDUCATION_START'];
  payload: IStartEducationPayload;
}

interface ICompleteEducationActionParams {
  type: PLAYER_ACTIONS['EDUCATION_COMPLETE'];
  payload: IEducationLevel;
}

interface IStartEducationActionData extends IMergePlayerData {
  questions: IQuestion[];
  isExceeded?: boolean;
}

interface ICompleteEducationActionData extends IMergePlayerData {
  learntSkills?: any;
}

interface IStartEducationAction {
  params: IStartEducationActionParams;
  data: IStartEducationActionData;
}

interface ICompleteEducationAction {
  params: ICompleteEducationActionParams;
  data: ICompleteEducationActionData;
}

interface IBuySkillsPayload {
  skillNames: string[];
}

interface IBuySkillsData extends IMergePlayerData {
  incrementedSkills: IAbility[];
}

interface IBuyPossessionPayload {
  item: string;
  level: TPossessionLevel;
}

interface IBuyTravelPayload {
  country: string;
  level: string;
}

interface IChangeAvatarPayload {
  icon: string;
}

interface IChangeAvatarData extends IMergePlayerData {
  isBuy: boolean;
}

interface IGetJobPayload {
  sector: string;
  job: string;
  companyName: string;
}

interface IPromotePayload {
  job: string;
}

interface IIncrementSkillsPayload {
  skillNames: string[];
}

interface IIncrementSkillsData extends IMergePlayerData {
  incrementedSkills: IAbility[];
}

interface IEndWorkPayload {
  rating: number;
}

export interface IEndWorkData extends IMergePlayerData {
  isPassed?: boolean;
  promotes?: string[];
  isLastAvailableCycle?: boolean;
}

interface ICompleteTutorialPayload {
  tutorialKey: keyof IPlayerTutorials;
}

interface IGaugesCompleteMiniGamePayload {
  isPassed: boolean;
  isTimedOut: boolean;
  gauge: keyof IPlayerGauges;
}

interface IGaugesCompleteMiniGameData extends IMergePlayerData {
  gaugeAdd?: number;
}

type TAction = IStartEducationAction | ICompleteEducationAction;

interface IActions {
  ['@EDUCATION/START']: {
    payload: IStartEducationPayload;
    data: IStartEducationActionData;
  };
  ['@EDUCATION/COMPLETE']: {
    payload: IEducationLevel;
    data: ICompleteEducationActionData;
  };
  ['@COURSE/BUY_SKILLS']: {
    payload: IBuySkillsPayload;
    data: IBuySkillsData;
  };
  ['@SHOP/BUY_POSSESSION']: {
    payload: IBuyPossessionPayload;
    data: IMergePlayerData;
  };
  ['@SHOP/CHANGE_AVATAR']: {
    payload: IChangeAvatarPayload;
    data: IChangeAvatarData | null;
  };
  ['@SHOP/BUY_TRAVEL']: {
    payload: IBuyTravelPayload;
    data: IMergePlayerData;
  };
  ['@WORK/GET_JOB']: {
    payload: IGetJobPayload;
    data: IMergePlayerData;
  };
  ['@WORK/QUIT_JOB']: {
    payload: undefined;
    data: IMergePlayerData;
  };
  ['@WORK/START_WORK']: {
    payload: undefined;
    data: IMergePlayerData;
  };
  ['@WORK/CHOOSE_RELEVANT_SKILLS']: {
    payload: undefined;
    data: IMergePlayerData;
  };
  ['@WORK/INCREMENT_SKILLS']: {
    payload: IIncrementSkillsPayload;
    data: IIncrementSkillsData;
  };
  ['@WORK/END_WORK']: {
    payload: IEndWorkPayload;
    data: IEndWorkData;
  };
  ['@WORK/PROMOTE']: {
    payload: IPromotePayload;
    data: IMergePlayerData;
  };
  ['@WORK/APPLY_JOB']: {
    payload: undefined;
    data: IMergePlayerData;
  };
  ['@WORK/TRY_OTHER']: {
    payload: undefined;
    data: IMergePlayerData;
  };
  ['@WORK/LIMIT_AVAILABLE_JOBS']: {
    payload: ILimitAvailableJobsActionParams;
    data: [string, boolean][];
  };
  ['@TUTORIALS/COMPLETE']: {
    payload: ICompleteTutorialPayload;
    data: IMergePlayerData;
  };
  ['@LIFE_GOALS/CHANGE']: {
    payload: { indexes: number[] };
    data: IMergePlayerData;
  };
  ['@LIFE_GOALS/CONFIRM']: {
    payload: { index: number };
    data: IMergePlayerData;
  };
  ['@GAUGES/COMPLETE_MINI_GAME']: {
    payload: IGaugesCompleteMiniGamePayload;
    data: IGaugesCompleteMiniGameData;
  };
}

interface ICallableFunctions {
  authPlayer: { params: IAuthPlayerParams; data: string };
  connectGame: { params: IGameIdentity; data: IConnectGameData };
  createCharacter: {
    params: ICreateCharacterParams;
    data: ICreateCharacterData;
  };
  getLifeCards: { params: IClientIdParam; data: IGetLifeCardsData };
  applyLifeCard: { params: IApplyLifeCardParams; data: IApplyLifeCardData };
  payLifeExpenses: {
    params: IClientIdParam;
    data: IPayLifeExpensesData | null;
  };
  applyRound: { params: IClientIdParam; data: IStartRoundData | null };
  action: TAction;
}

const callableFunctionAction = firebaseFunctions.httpsCallable('action');

export const callFunctionAction = async <T extends keyof IActions>(
  type: T,
  payload: IActions[T]['payload']
): Promise<IActions[T]['data']> => {
  const result = await callableFunctionAction({
    clientId: CLIENT_ID,
    action: { type, payload },
  });
  return result.data;
};

export const callFunction =
  <T extends keyof ICallableFunctions>(funcName: T) =>
  async (
    params: ICallableFunctions[T]['params']
  ): Promise<ICallableFunctions[T]['data']> => {
    const callableFunction = firebaseFunctions.httpsCallable(funcName);

    const result = await callableFunction(params);
    return result.data;
  };

const callableFunctions = {
  authPlayer: firebaseFunctions.httpsCallable('authPlayer'),
  connectGame: firebaseFunctions.httpsCallable('connectGame'),
};

class CallableFunctionsService {
  async connectGame(params: IAuthPlayerParams) {
    const result = await callableFunctions.authPlayer(params);
    return result.data as string;
  }
}

export const callableFunctionsService = new CallableFunctionsService();
