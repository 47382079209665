import { lazy, Suspense } from 'react';

import { Greeting } from '../greeting';

const SelfPacedGameCoreLoader = lazy(
  () => import('./standard-game-core-loader')
);

export const StandardGameCoreLoaderLazy = () => {
  return (
    <Suspense fallback={<Greeting />}>
      <SelfPacedGameCoreLoader />
    </Suspense>
  );
};
