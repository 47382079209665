import { action } from 'services/utils';

import { MAIN_ACTION } from './main.constants';
import { IMainRedux } from './main.typing';

const {
  CLEAR,
  SET_SHOW_BOTTOM_BAR,
  SET_IS_TESTED,
  OPEN_TEST,
  CLOSE_TEST,
  UPDATE,
} = MAIN_ACTION;

export const MainActions = {
  clear: () => action(CLEAR),

  openTest: () => action(OPEN_TEST),

  closeTest: () => action(CLOSE_TEST),

  setShowBottomBar: (isShowBottomBar: boolean) =>
    action(SET_SHOW_BOTTOM_BAR, isShowBottomBar),

  setIsTested: (status: boolean) => action(SET_IS_TESTED, status),

  update: (payload: Partial<IMainRedux>) => action(UPDATE, payload),
};

export type TMainActions = ReturnType<TActionCombiner<typeof MainActions>>;
