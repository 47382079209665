import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TGameStatus } from '@avid/common';

import { GameActions } from 'services/redux';
import { getRoundSecondsLeft } from 'services/utils';

import { useReduxSelector } from './redux';
import { useEndSelfPacedRound } from './self-paced';

export const useRoundCountdown = () => {
  const dispatch = useDispatch();

  const roundDuration = useReduxSelector(
    (redux) => redux.game.config?.roundDuration || 0
  );
  const roundsNumber = useReduxSelector(
    (redux) => redux.game.config?.roundsNumber || 0
  );
  const roundNumber = useReduxSelector((redux) => redux.game.round.number);

  const gameStatus = useReduxSelector((redux) => redux.game.status);
  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );

  const { endSelfPacedRound } = useEndSelfPacedRound();

  const updateRound = useCallback(
    (secondsPassed: number) => {
      const secondsLeft = getRoundSecondsLeft(roundDuration, secondsPassed);

      if (secondsLeft > 0) {
        return;
      }

      const status: TGameStatus =
        roundNumber >= roundsNumber ? 'finished' : 'paused';

      dispatch(GameActions.merge({ status }));

      if (isSelfPaced && gameStatus === 'in-progress') {
        endSelfPacedRound();
      }
    },
    [
      dispatch,
      endSelfPacedRound,
      gameStatus,
      isSelfPaced,
      roundDuration,
      roundNumber,
      roundsNumber,
    ]
  );

  return updateRound;
};
