export const FONT_SIZES = {
  px36: '36px',
  title: '32px',
  big: '24px',
  header: '20px',
  px18: '18px',
  regular: '16px',
  px15: '15px',
  small: '14px',
  px12: '12px',
  little: '10px',
  9: '9px',
  11: '11px',
  13: '13px',
  15: '15px',
  17: '17px',
  22: '22px',
  30: '30px',
  36: '36px',
  96: '96px',
} as const;

export type TFontSizeNames = keyof typeof FONT_SIZES;
