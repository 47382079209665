import { useReduxSelector, useTotalSalary } from 'services/hooks';

export interface IJobPromotionItemProps {
  job: string;
  onSelectWork(job: string): void;
  selectJob?: string;
}

export const useJobPromotionItem = (props: IJobPromotionItemProps) => {
  const { job } = props;

  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const companyBonus = useReduxSelector(
    (redux) => redux.player.work?.company.bonus
  );

  const { isLoading, totalSalary } = useTotalSalary({
    sector,
    job,
    companyBonus,
  });

  return { totalSalary, isLoading };
};
