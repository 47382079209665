import dayjs from 'dayjs';
import { IPlayerSelfPacedRound } from '@avid/common';

import { getNowMoment } from './time';

export const getSelfPacedRoundDuration = (startAt: string) => {
  const now = getNowMoment();

  return dayjs(now).diff(startAt, 'second');
};

export const getSelfPacedActualRoundDuration = (
  round: IPlayerSelfPacedRound,
  roundDuration: number
) => {
  let calculated = 0;

  if (round.endAt) {
    calculated = dayjs(round.endAt).diff(round.startAt, 'second');
  } else {
    calculated = getSelfPacedRoundDuration(round.startAt);
  }

  return Math.min(calculated, roundDuration);
};
