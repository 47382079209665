import { useDispatch } from 'react-redux';

import { MainActions } from 'screens/main';
import { CostModal } from 'components/modal';
import { useReduxSelector } from 'services/hooks';
import { Theme } from 'themes';

import { FIND_JOB_STRINGS } from '../../../find-job.strings';

interface IProps {
  onConfirm: (energy: number, money?: number) => void;
  onClose: () => void;
}

const { SELECT_JOB } = FIND_JOB_STRINGS;

export const ConfirmJobModal = (props: IProps) => {
  const { onConfirm, onClose } = props;

  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );
  const money = useReduxSelector((redux) => redux.app.PRICE_SELECT_JOB.money);
  const energy = useReduxSelector(
    (redux) => redux.app.PRICE_SELECT_JOB.energy || 0
  );

  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (isSelfPaced) {
      dispatch(MainActions.update({ isActing: true }));
    }

    onConfirm(energy, money);
  };

  return (
    <CostModal
      cancelButtonTitle={SELECT_JOB.BACK_BUTTON}
      confirmButtonTitle={SELECT_JOB.CONFIRM_BUTTON}
      energy={energy}
      onCloseModal={onClose}
      onConfirm={handleConfirm}
      itemGap={{ default: '16px' }}
      money={money}
      title={
        <Theme.Paragraph $fontFamily="bold">{SELECT_JOB.TITLE}</Theme.Paragraph>
      }
    />
  );
};
