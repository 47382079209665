import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { COLORS, Theme } from 'themes';

interface ILoaderStyled {
  $size?: string;
  $borderSize?: string;

  $loaderColor: string;
  $backgroundColor: string;
}

const LOADER_STYLED: Record<string, ILoaderStyled> = {
  default: {
    $borderSize: '16px',
    $backgroundColor: COLORS.plumpPurple,
    $loaderColor: COLORS.darkYellow,
  },
  buttonLoader: {
    $size: '20px',
    $backgroundColor: COLORS.plumpPurple,
    $loaderColor: COLORS.darkYellow,
    $borderSize: '5px',
  },
};

interface ILoaderProps {
  isLoading?: boolean;
  isInline?: boolean;
  variant?: keyof typeof LOADER_STYLED;
  className?: string;
  children?: ReactNode;
}

const LoaderStyled = styled.div<ILoaderStyled>`
  border: ${(props) => props.$borderSize} solid
    ${(props) => props.$backgroundColor};
  border-top: ${(props) => props.$borderSize} solid
    ${(props) => props.$loaderColor};
  border-radius: 50%;
  width: ${(props) => props.$size || '120px'};
  height: ${(props) => props.$size || '120px'};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Wrapper = styled(Theme.FullSizeCentralizeFlexbox)<{
  $isInline?: boolean;
}>`
  width: 100%;
  height: 100%;

  ${({ $isInline }) => $isInline && 'display: inline'};
`;

export const Loader: FC<ILoaderProps> = (props) => {
  const {
    isLoading = true,
    isInline = false,
    children,
    variant = 'default',
    className,
  } = props;

  const styles = LOADER_STYLED[variant];

  if (isLoading) {
    return (
      <Wrapper className={className} $isColumn $isInline={isInline}>
        <LoaderStyled {...styles} />
      </Wrapper>
    );
  }

  return <>{children}</>;
};
