import { useContext, useState } from 'react';

import { useReduxSelector } from 'services/hooks';

import { WorkContext } from '../../work.context';

export const useJobPromotion = () => {
  const isIntern = useReduxSelector((redux) => redux.player.work?.isIntern);

  const { nextLevels } = useContext(WorkContext);

  const [selectedJob, setSelectedJob] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const setWorkData = (job: string) => setSelectedJob(job);

  return {
    isIntern,
    nextLevels,
    isLoading,
    selectedJob,
    setWorkData,
    setIsLoading,
  };
};
