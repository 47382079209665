import { InfoImageModal } from 'components/modal';
import { Theme } from 'themes';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { usePromotion } from './promotion.state';

const { CAREER_UP } = WORK_STRINGS;

export const Promotion = () => {
  const { isIntern, onClose } = usePromotion();

  const title = CAREER_UP[isIntern ? 'TITLE_INTERN' : 'TITLE'];
  const subtitle = CAREER_UP[isIntern ? 'SUBTITLE_INTERN' : 'SUBTITLE'];

  const Title = <Theme.Title>{title}</Theme.Title>;
  const Subtitle = <Theme.Paragraph>{subtitle}</Theme.Paragraph>;

  return (
    <InfoImageModal
      title={Title}
      subtitle={Subtitle}
      image="getNewWork"
      buttonText={CAREER_UP.BUTTON}
      onCloseModal={onClose}
    />
  );
};
