import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { usePushLog, useReduxSelector } from 'services/hooks';
import { PlayerActions } from 'services/redux';
import { callFunctionAction } from 'services/api';
import { replaceRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { WorkModal } from '../../work.typings';
import { WorkContext } from '../../work.context';

export interface IUseJobPromotionButtonProps {
  isLoading: boolean;
  onSetLoading: (isLoading: boolean) => void;
  selectedJob?: string;
}

export const useJobPromotionButtons = (props: IUseJobPromotionButtonProps) => {
  const { isLoading, selectedJob, onSetLoading } = props;

  const isIntern = useReduxSelector((redux) => redux.player.work?.isIntern);

  const { onOpenModal } = useContext(WorkContext);

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const onConfirm = async () => {
    if (isLoading || !selectedJob) {
      return;
    }

    onSetLoading(true);

    try {
      const result = await callFunctionAction('@WORK/PROMOTE', {
        job: selectedJob,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));

      pushLog({
        type: 'work',
        action: 'promoted',
        params: {
          position: selectedJob,
        },
      });

      onOpenModal(WorkModal.JobCongratulation);
    } catch (error) {
      console.error(error);
    } finally {
      onSetLoading(false);
    }
  };

  const onReject = () => {
    replaceRoute(ROUTES.findJob);
  };

  return { isIntern, onConfirm, onReject };
};
