import { TMainActions } from './main.actions';

import { IMainRedux } from './main.typing';
import { INIT_REDUX_MAIN, MAIN_ACTION } from './main.constants';

const {
  CLEAR,
  SET_SHOW_BOTTOM_BAR,
  SET_IS_TESTED,
  OPEN_TEST,
  CLOSE_TEST,
  UPDATE,
} = MAIN_ACTION;

export const reducerMain = (
  state = INIT_REDUX_MAIN,
  action: TMainActions
): IMainRedux => {
  switch (action.type) {
    case CLEAR: {
      return INIT_REDUX_MAIN;
    }

    case SET_SHOW_BOTTOM_BAR: {
      return { ...state, isShowBottomBar: action.payload };
    }

    case SET_IS_TESTED: {
      return { ...state, isTested: action.payload };
    }

    case OPEN_TEST: {
      return { ...state, isShowBottomBar: false, isTested: false };
    }

    case CLOSE_TEST: {
      return { ...state, isShowBottomBar: true, isTested: true };
    }

    case UPDATE: {
      return { ...state, ...action.payload };
    }

    default:
      return state ?? INIT_REDUX_MAIN;
  }
};
