import {
  ICareer,
  ICareerAnchor,
  ICareerUp,
  ISkillInfo,
  TGameVersion,
  TWorkExperience,
} from '@avid/common';

export enum WorkPage {
  Start = 'START',
  ChooseSkill = 'CHOOSE_SKILL',
  Test = 'TEST',
  JobPromotion = 'JobPromotion',
}

export enum WorkModal {
  StartTest = 'START_TEST',
  StartTestRelevant = 'START_TEST_RELEVANT',
  CompleteWork = 'COMPLETE_WORK',
  CareerUp = 'CAREER_UP',
  FailedWork = 'FAILED_WORK',
  CyclesExceed = 'CYCLES_EXCEED',
  QuiteWork = 'QuiteWork',
  JobCongratulation = 'JobCongratulation',
  ClickSkill = 'ClickSkill',
  BudgetingPropose = 'BudgetingPropose',
}

export interface IWorkStateValues {
  positive: ICompanyParameter[];
  negative: ICompanyParameter[];
}

export interface IWorkState {
  isLoadingValues: boolean;
  page: WorkPage;
  skills: ISkillInfo[];
  rating: number;
  isSelfSelect: boolean;
  nextLevels: string[];
  values: IWorkStateValues | null;
  modal?: WorkModal;
}

export interface IWorkContext extends IWorkState {
  onOpenPage: (page: WorkPage) => void;
  onOpenModal: (modal: WorkModal) => void;
  onCloseModal: () => void;
  onSetSkills: (skills: ISkillInfo[]) => void;
  onSetRating: (rating: number) => void;
  onSetSelfSelect: () => void;
  onSetNextLevels: (works: string[]) => void;
  onReset: (statePayload?: Partial<IWorkState> | undefined) => void;
}

export interface ICompleteWorkParams {
  isPass: boolean;
  limitNotPassWork: number;
}

export interface IStartWorkTestParams {
  relativeSkills?: ISkillInfo[];
}

export interface IWorkData extends ICareerUp {
  salary: number;
  companyName: string;
  job: string;
  sector: string;
}

export interface IWorkJSXProps {
  onOpenPage: (page: WorkPage) => void;
  onOpenModal: (modal: WorkModal) => void;
}

export interface ISuccessWorkParams {
  code: string;
  email: string;
  sector: string;
  career: ICareer[];
  experience: TWorkExperience;
  work: string;
}

export interface IFailWorkParams {
  code: string;
  email: string;
  countNotPassWork: number;
  limitNotPassWork: number;
}

export interface IFormValuesParams {
  version: TGameVersion;
  positiveValues: string[];
  negativeValues: string[];
  constAnchors: ICareerAnchor[];
  constValues: Record<string, string[]>;
  chAnchors: string[];
  chValues: string[];
}
