import styled from 'styled-components';

import { Checkbox } from 'components/checkbox';
import { Loader } from 'components/loader';
import { Theme } from 'themes';

import { WORK_STRINGS } from '../../../work.strings';

import {
  IJobPromotionItemProps,
  useJobPromotionItem,
} from './job-promotion-item.state';

const Item = styled(Checkbox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const { SALARY } = WORK_STRINGS.JOB_PROMOTION;

export const JobPromotionItem = (props: IJobPromotionItemProps) => {
  const { job, selectJob, onSelectWork } = props;

  const { totalSalary, isLoading } = useJobPromotionItem(props);

  if (isLoading) {
    return <Loader />;
  }

  const isChecked = selectJob === job;

  return (
    <Item isChecked={isChecked} setValue={onSelectWork} item={{ value: job }}>
      <Theme.Paragraph $fontFamily="bold">{job}</Theme.Paragraph>

      <Theme.Paragraph $fontFamily="regular">
        {SALARY(totalSalary)}
      </Theme.Paragraph>
    </Item>
  );
};
