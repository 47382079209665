import { useReduxSelector } from 'services/hooks';

import { SelfPacedGameCoreLoaderLazy } from './self-paced-game-core-loader';
import { StandardGameCoreLoaderLazy } from './standard-game-core-loader';

export const GameFlowProxy = () => {
  const isSelfPaced = useReduxSelector(
    (redux) => redux.game.config?.isSelfPaced
  );

  if (isSelfPaced) {
    return <SelfPacedGameCoreLoaderLazy />;
  }

  return <StandardGameCoreLoaderLazy />;
};
