import { IPlayerSelfPacedRound, ISentReflectionAnswer } from '@avid/common';

import { IStartRoundData } from 'typings/rounds';
import { IMergePlayerData } from 'typings';

import { firebaseFunctions } from '../../firebase';

interface ISelfPacedStartRoundData extends IStartRoundData {
  round: number;
  startAt: string;
}

interface ISyncRoundsData extends IMergePlayerData {
  playerRounds: IPlayerSelfPacedRound[];
}

interface IConfirmAnswersPayload {
  isEndGame: boolean;
}

export interface ISelfPacedActions {
  ['@SELF_PACED/START_ROUND']: {
    payload: null;
    data: ISelfPacedStartRoundData | null;
  };
  ['@SELF_PACED/SYNC_ROUNDS']: {
    payload: null;
    data: ISyncRoundsData;
  };
  ['@SELF_PACED/END_ROUND']: {
    payload: null;
    data: IMergePlayerData | null;
  };
  ['@SELF_PACED/ANSWER_QUESTION']: {
    payload: ISentReflectionAnswer;
    data: null;
  };
  ['@SELF_PACED/CONFIRM_ANSWERS']: {
    payload: IConfirmAnswersPayload;
    data: IMergePlayerData;
  };
}

const callableFunctionSelfPaced = firebaseFunctions.httpsCallable('selfPaced');

export const callSelfPacedAction = async <T extends keyof ISelfPacedActions>(
  type: T,
  payload: ISelfPacedActions[T]['payload']
): Promise<ISelfPacedActions[T]['data']> => {
  const result = await callableFunctionSelfPaced({
    action: { type, payload },
  });

  return result.data;
};
