import { Route, Switch } from 'react-router-dom';

import { Auth } from 'screens/auth';
import { Login } from 'screens/log-in';
import { ConnectGame } from 'screens/connect-game';
import { GameReport, GameReportToken } from 'screens/mods';
import { GameFlowProxy } from 'screens/game-flow-proxy';
import { Onboarding } from 'screens/onboarding';
import { GAME_ROUTES, ROUTES } from 'constants/routes';
import { combineModsRoutes } from 'services/utils';
import { AuthRoute } from 'components/auth-route';

import { CreateCharacterRoute } from '../../create-char';
import { WelcomePage } from '../../welcome-page';

export const AppRouter = () => (
  <Switch>
    <Route exact path={ROUTES.greeting} component={Login} />
    <Route path={ROUTES.login} component={Login} />
    <Route path={ROUTES.auth} component={Auth} />
    <Route
      path={ROUTES.gameReportToken + '/:gameCode'}
      component={GameReportToken}
    />

    <AuthRoute path={ROUTES.joinGame} component={ConnectGame} />
    <AuthRoute path={ROUTES.onboarding} component={Onboarding} />
    <AuthRoute path={ROUTES.createCharacter} component={CreateCharacterRoute} />
    <AuthRoute path={ROUTES.welcome} component={WelcomePage} />
    <AuthRoute
      path={combineModsRoutes(GAME_ROUTES)}
      component={GameFlowProxy}
      isCharacter
    />
    <AuthRoute path={ROUTES.gameReport + '/:gameCode'} component={GameReport} />
  </Switch>
);
