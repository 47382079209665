import { IMainRedux } from './main.typing';

export const MAIN_ACTION = <const>{
  CLEAR: '@MAIN/CLEAR',
  SET_SHOW_BOTTOM_BAR: '@MAIN/SET_SHOW_BOTTOM_BAR',
  SET_IS_TESTED: '@MAIN/SET_IS_TESTED',
  OPEN_TEST: '@MAIN/OPEN_TEST',
  CLOSE_TEST: '@MAIN/CLOSE_TEST',
  UPDATE: '@MAIN/UPDATE',
};

export const INIT_REDUX_MAIN: IMainRedux = {
  isActing: false,
  isShowBottomBar: true,
  isTested: true,
};
