import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { removeFiredPlayer, firedPlayersSelfRef } from '../api';
import { PlayerActions } from '../redux';

import { usePushLog } from './push-log';
import { useReduxSelector } from './redux';

const PLAYER_FIRED_WORK = {
  work: '',
  countNotPassWork: 0,
} as const;

interface IProps {
  onVal?: (val: any) => void;
  once?: (val: any) => void;
}

export const useRealtimeFire = (props: IProps) => {
  const { onVal, once } = props;

  const gameCode = useReduxSelector((redux) => redux.player.authInfo.code);
  const email = useReduxSelector((redux) => redux.player.authInfo.email);

  const dispatch = useDispatch();

  const pushLog = usePushLog();

  const quitWorkAndPushLog = useCallback(() => {
    dispatch(PlayerActions.updateWork(PLAYER_FIRED_WORK));

    removeFiredPlayer(gameCode, email);

    pushLog({ type: 'work', action: 'quit', params: { reason: 'limits' } });
  }, [dispatch, email, gameCode, pushLog]);

  useEffect(() => {
    if (!gameCode || !email) {
      return;
    }

    const ref = firedPlayersSelfRef(gameCode, email);

    ref[once ? 'once' : 'on']('value', (snapshot) => {
      const val = snapshot.val();

      if (val) {
        quitWorkAndPushLog();
      }

      once ? once(val) : onVal?.(val);
    });

    return () => ref.off();
  }, [email, gameCode, onVal, once, quitWorkAndPushLog]);
};
