import { useContext } from 'react';

import { useReduxSelector } from 'services/hooks';

import { WorkContext } from 'screens/work/work.context';
import { WorkPage } from 'screens/work/work.typings';

export const usePromotion = () => {
  const { onOpenPage, onCloseModal } = useContext(WorkContext);

  const isIntern = useReduxSelector((redux) => redux.player.work?.isIntern);

  const onClose = () => {
    onOpenPage(WorkPage.JobPromotion);
    onCloseModal();
  };

  return { isIntern, onClose };
};
